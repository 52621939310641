/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

$primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$accent: mat.m2-define-palette(mat.$m2-green-palette, A200);

$theme: mat.m2-define-light-theme((
 color: (
   primary: $primary,
   accent: $accent,
 ),
 density: 0,
));

@include mat.all-component-themes($theme);


// Imports for symbols and body
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');
@import "@angular/material/prebuilt-themes/indigo-pink.css";

// @import 'mapbox-gl/dist/mapbox-gl.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.app-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.app-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.app-center {
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
}

.splash {
  opacity: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #b9bbbee3;
  padding-top: 25vh;
  text-align: center;
  z-index: -1;
  transition: opacity .8s ease-out;
}

app-root:empty+.splash {
  opacity: 1;
  z-index: 100;
  transition: opacity .8s ease-in;
}
